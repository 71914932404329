<template>
    <div class="score-container">
        <div class="tab">
            <span class="name">状态</span>
            <el-select v-model="correctValue" placeholder="请选择" @change="toggleStatus">
                <el-option v-for="item in correctOption" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
        </div>
        <el-table :data="infoList" class="customTable" style="width: 100%; flex: 1; margin-top: 18px" height="1%">
<!--            <el-table-column prop="nickname" label="学生名字" align="center"></el-table-column>
            <el-table-column prop="eaxm_name" label="考试名称"></el-table-column>-->
            <el-table-column label="考试模块">
                <template slot-scope="scope">电子商务数据报告制作</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center"></el-table-column>
            <el-table-column prop="exam_time" label="考试时间" align="center"></el-table-column>
            <el-table-column>
                <template #header>评分（最高分数为{{maxScore}}分）</template>
                <template slot-scope="scope">
                    <div v-if="scope.row.status === '未提交'">该学生暂未提交无法评分</div>
                    <div v-else-if="scope.row.status !== '未提交'">
                        <el-input-number v-model="scope.row.mark_score" :ref="`score_${scope.row.id}`" :min="0" :max="maxScore" :precision="1" :controls="false" style="width: 88px; margin-right: 10px"></el-input-number>
                        <el-link v-if="scope.row.status === '待批改'" type="warning" :underline="false" @click="rateBtn(scope.row.id, scope.row.mark_score)">确定</el-link>
                        <el-link v-if="scope.row.status === '已批改'" type="warning" :underline="false" @click="reRateBtn(scope.row.id, scope.row.mark_score)">重新批改</el-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" v-if="scope.row.status !== '未提交'" :href="scope.row.file_path">下载文件</el-link>
                    <el-link type="primary" :underline="false" @click="standardBtn(scope.row.op_id)">评分标准</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>

        <el-dialog title="评分标准" :visible.sync="dialogVisible" width="830px" custom-class="custom-dialog" @opened="openedDialog" @close="closeDialog">
            <el-table :data="scoreCriteria" class="customTable" style="width: 100%;">
                <el-table-column prop="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="content" label="考核内容"></el-table-column>
                <el-table-column prop="require" label="考核要点"></el-table-column>
                <el-table-column prop="standard" label="评分标准"></el-table-column>
                <el-table-column prop="score" label="配分" align="center"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    import { teacheropmJudgesCommercel, teacheropmGetJudgesStandard, teacheropmScoreCommercel, teacheropmReCorrecting } from '@/utils/apis.js'
    export default {
        data() {
            return {
                correctOption: [
                    {
                        id: 2,
                        label: '未批改'
                    },
                    {
                        id: 1,
                        label: '已批改'
                    },
                    {
                        id: 0,
                        label: '未提交'
                    }
                ],
                correctValue: 2, // 1 已批改 2未批改 不填或0未提交
                // correctValue: 1,
                maxScore: null,
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // S弹窗
                dialogVisible: false,
                currentOpId: null,
                scoreCriteria: [],
                // E弹窗
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    exam_id: this.$route.query.examId,
                    status: this.correctValue,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                teacheropmJudgesCommercel(params).then((res) => {
                    this.maxScore = res.data.high_score
                    this.listPages.total = res.data.total
                    if (res.data.list.length > 0) {
                        res.data.list.forEach(item => {
                            item.mark_score = item.mark_score || void 0
                        })
                        this.infoList = res.data.list
                        this.currentOpId = res.data.list[0].op_id
                        this.getScoreCriteria()
                    } else {
                        this.infoList = []
                    }
                }).catch((err) => {})
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 切换状态
            toggleStatus() {
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 打开评分标准弹窗
            standardBtn(id) {
                this.dialogVisible = true
            },
            // 获取评分标准列表
            getScoreCriteria() {
                teacheropmGetJudgesStandard({op_id: this.currentOpId}).then((res) => {
                    this.scoreCriteria = res.data.scoring_criteria
                }).catch((err) => {})
            },
            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {

            },
            // 评分
            rateBtn(id, score) {
                if (score === void 0) {
                    this.$refs[`score_${id}`].$el.classList.add('isError')
                    return this.$message.warning('请输入分值')
                }
                let params = {
                    id: id,
                    score: score,
                }
                teacheropmScoreCommercel(params).then((res) => {
                    this.$message.success(res.msg)
                    this.getList()
                }).catch((err) => {})
            },
            // 重新批改
            reRateBtn(id, score) {
                if (score === void 0) {
                    this.$refs[`score_${id}`].$el.classList.add('isError')
                    return this.$message.warning('请输入分值')
                }
                let params = {
                    id: id,
                    score: score,
                }
                teacheropmReCorrecting(params).then((res) => {
                    this.$message.success(res.msg)
                    this.getList()
                }).catch((err) => {})
            },
        }
    }
</script>

<style scoped lang="scss">
    .score-container {
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
    .tab {
        .name {
            margin: 0 10px 0 0;
        }
    }
</style>